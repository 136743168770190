//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppSelect from "@/components/AppSelect.vue";
import EntitySearch from "@/components/EntitySearch.vue";
import { get_KeywordAnalyzeType_Array } from "@/utils/enums";
import Tooltip from "@/components/Tooltip";
import dayjs from "dayjs";
export default {
  name: "KeywordSearch",
  props: {
    typeId: {
      type: String,
      default: "std"
    },
    searchValueDefault: {
      type: String,
      default: ""
    },
    limit: {
      type: Object
    }
  },
  data() {
    const select_KeywordAnalyzeType_items = [...get_KeywordAnalyzeType_Array()];
    return {
      select_KeywordAnalyzeType_items,
      keywordAnalyzeType: select_KeywordAnalyzeType_items.find(item => item.id === this.typeId),
      searchValue: this.searchValueDefault,
      execute: 0,
      snap: null,
      kwd: null,
      qTypeUrlBadFormat: 0
    };
  },
  components: {
    AppSelect,
    EntitySearch,
    Tooltip
  },
  computed: {
    searchValueIsEmpty() {
      return !this.searchValue.length;
    },
    KeywordAnalyzeType_id() {
      return this.keywordAnalyzeType.id;
    },
    placeholder() {
      let value = "";
      if (this.KeywordAnalyzeType_id === "std") {
        value = "Поиск по запросам";
      }
      if (this.KeywordAnalyzeType_id === "live") {
        value = "Введите поисковый запрос, например: свечи декоративные";
      }
      if (this.KeywordAnalyzeType_id === "url") {
        value = this.$route.params.mp === "wb" ? "Введите ссылку с маркетплейса, например: https://www.wildberries.ru/catalog/0/search.aspx?page=1&sort=popular&search=платье&fkind=2&f23796=31139&f10=10071&fcolor=11393254" : "Введите ссылку с маркетплейса, например: https://www.ozon.ru/category/platya-zhenskie-7502/?category_was_predicted=true&deny_category_prediction=true&from_global=true&text=%D0%BF%D0%BB%D0%B0%D1%82%D1%8C%D0%B5";
      }
      return value;
    },
    limitReached() {
      return this.limit.count >= this.limit.limit;
    }
  },
  methods: {
    qTypeUrlBadFormat_emiting(val) {
      //console.log("qTypeUrlBadFormat_emiting.133: val=", val);
      this.qTypeUrlBadFormat = val;
    },
    search() {
      this.execute++;
    },
    changeSearchValue(val) {
      this.searchValue = val;
    },
    searchLoader(query) {
      return this.$store.dispatch("keyword/getKeywordByName", {
        query,
        mp: this.$route.params.mp
      });
    },
    getRoute(item) {
      //console.log("152: item=", item);
      //console.log("152: this.keywordAnalyzeType.id=",this.keywordAnalyzeType.id);

      let mp = this.$route.params.mp;
      if (["live", "url"].includes(this.keywordAnalyzeType.id)) {
        //snapshot имеет каждые 10 минут
        this.snap = dayjs().format("YYYYMMDDHHmm");
        this.snap = this.snap.slice(0, -1) + "0";
        this.kwd = item !== null && item !== void 0 && item.item ? item.item.kwd : item.keyword;
        if (item !== null && item !== void 0 && item.item) {
          mp = item.mp;
        }
      }
      let query = this.keywordAnalyzeType.id == "std" ? {} : {
        type: this.keywordAnalyzeType.id,
        snap: this.snap,
        kwd: this.kwd
      };

      //console.log("174: query=", query);

      return {
        name: "KeywordInfo",
        params: {
          mp,
          k_id: item.k_id
        },
        query: {
          ...query
        }
      };
    }
  }
};