//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainPage from "@/components/MainPage.vue";
import MainTitle from "@/components/MainTitle.vue";
import KeywordsTable from "@/components/Rating/KeywordsTable";
import KeywordSearch from "@/components/Keyword/Search.vue";
import { dateRangeFromDate, today, dateRangeBoundaries } from "@/components/RangeCalendar.vue";
import CalendarMixin from "@/mixins/Calendar";
export default {
  name: "Home",
  mixins: [CalendarMixin],
  meta() {
    return {
      title: this.$route.params.mp === "wb" ? "Аналитика и статистика продаж маркетплейса Wildberries, анализ лучших категорий и ниш для продаж на Вайлдберриз, анализ конкурентов" : "Аналитика и статистика продаж маркетплейса OZON, анализ лучших категорий и ниш для продаж на ОЗОН, анализ конкурентов",
      meta: [{
        name: "description",
        content: this.$route.params.mp === "wb" ? "SalesFinder - сервис детальной аналитики товаров Вайлдберриз! Анализ категорий, подбор ниш и товаров для продажи, анализ конкурентов Wildberries, поисковые запросы, кабинет продавца по API" : "SalesFinder - сервис детальной аналитики товаров OZON! Анализ категорий, подбор ниш и товаров для продажи, анализ конкурентов ОЗОН, поисковые запросы, кабинет продавца по API"
      }]
    };
  },
  data() {
    return {
      limitKwd: {}
    };
  },
  async created() {
    await this.loadKwdLimits();
  },
  methods: {
    async loadKwdLimits() {
      this.limitKwd = await this.$store.dispatch("keyword/getKeywordLimit", {
        fake: 1 //this.$route.params.id,
      });
      //console.log("loadKwdLimits.221: this.limitKwd=", this.limitKwd);
    }
  },

  components: {
    MainPage,
    MainTitle,
    KeywordsTable,
    KeywordSearch
  },
  computed: {
    ratingBindings() {
      var _this$calendarBoundar;
      if (!this.calendarBoundaries) {
        return;
      }
      const lastAvailableDate = ((_this$calendarBoundar = this.calendarBoundaries) === null || _this$calendarBoundar === void 0 ? void 0 : _this$calendarBoundar[1]) || today();
      const weekRange = dateRangeFromDate(lastAvailableDate, 30);
      const [date, date2] = dateRangeBoundaries(weekRange, this.calendarBoundaries);

      //console.log('90: date=',date, 'date2=', date2)
      //console.log('91: this.route=',this.$route)

      return {
        action: "rating/getRatingKeywords",
        id: this._uid,
        routeParams: this.$route.params,
        reportSettings: {
          mp: this.$route.params.mp,
          metric: "revenue",
          date,
          date2,
          size: 20,
          ...this.$route.query
        },
        paginatable: false
      };
    }
  }
};